import { template as template_1ca34ef15c9d4d1ea3a8e3f78f181179 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1ca34ef15c9d4d1ea3a8e3f78f181179(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
