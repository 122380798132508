import { template as template_ed8733832aac45b3bad20e553214e39c } from "@ember/template-compiler";
const FKLabel = template_ed8733832aac45b3bad20e553214e39c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
