import { template as template_521fb2b1c60b4a19b538a6ec5b7d21c4 } from "@ember/template-compiler";
import { get } from "@ember/helper";
import UserFieldConfirm from "./user-fields/confirm";
import UserFieldDropdown from "./user-fields/dropdown";
import UserFieldMultiselect from "./user-fields/multiselect";
import UserFieldText from "./user-fields/text";
const COMPONENTS = {
    confirm: UserFieldConfirm,
    dropdown: UserFieldDropdown,
    multiselect: UserFieldMultiselect,
    text: UserFieldText
};
const UserField = template_521fb2b1c60b4a19b538a6ec5b7d21c4(`
  {{#let (get COMPONENTS @field.field_type) as |Component|}}
    <Component
      @field={{@field}}
      @value={{@value}}
      @validation={{@validation}}
      ...attributes
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserField;
